<script>
  import Icon from '@iconify/svelte';
  import defaultIcon from '@icons/ph/smiley-duotone';

  let { icon = defaultIcon, iconSize = '1.25rem', width, height, size,
        label, children, ...restProps } = $props();

  if (size) {
    iconSize = size;
  }

  if (!width) {
    width = iconSize;
  }
  if (!height) {
    height = iconSize;
  }

</script>

<style>
  Icon {
    margin-right: 0.2rem;
    margin-top: 0.1rem;
    flex-shrink: 0;
  }
  span.wrapper {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    overflow: hidden;
  }
</style>

{#if children}
<span class="wrapper">
  <Icon {icon} {width} {height} {...restProps} /><span>{@render children()}</span>
</span>
{:else if label}
<span>
  <Icon {icon} {width} {height} {...restProps} /><span>{label}</span>
</span>
{:else}
  <Icon {icon} {width} {height} {...restProps} />
{/if}

export const faqData = [
  {
    "question": "When should I book the appointment at the embassy?",
    "answer": "Please book the appointment at your local German embassy as soon as possible. Often you have waiting periods of several weeks or months. If the appointment can’t take place due to external circumstances, you can cancel it anytime."
  },
  {
    "question": "What kind of visa should I choose for the appointment at the embassy?",
    "answer": "Please go for an appointment for a national visa – this is generally under category type D. The reason you choose depends on your reason to immigrate, such as work, family reunion, or study purpose."
  },
  {
    "question": "What are the specific requirements for a blue card?",
    "answer": "To obtain a blue card, you need to fulfill three main requirements: your degree needs to be acknowledged in Germany, your annual salary needs to be at least EUR 55,200 (in 2020) or between EUR 43,056 and 55,200 (in 2020) if it is for a position where Germany faces a shortage of employees, mostly in MINT positions, and your offered position needs to fit your educational background. <a href='https://www.make-it-in-germany.com/en/visa/kinds-of-visa/eu-blue-card/' target='_blank'>More info</a>."
  },
  {
    "question": "What are the advantages of a blue card?",
    "answer": "A blue card has two main advantages - you can bring your family without them needing to show any German language skills, and you can apply for a permanent residence title after 33 months, or after 21 months with really good German skills. <a href='https://www.make-it-in-germany.com/en/visa/kinds-of-visa/eu-blue-card/' target='_blank'>More info</a>."
  },
  {
    "question": "Should I book an appointment for all family members?",
    "answer": "Yes, please make sure all your family members are included in the appointment, or book several ones, each for one family member. This depends on the structure of the individual German embassy responsible for you."
  },
  {
    "question": "Should I have a travel insurance for obtaining my visa?",
    "answer": "Yes, you should have travel insurance for getting your visa as it is one of the requirements. The insurance should cover the trip and often needs to cover the duration of the visa, which is at least 90 days."
  },
  {
    "question": "Do I need an apostille for my university degree?",
    "answer": "No, an officially accepted translation is sufficient. This needs to be done by a certified translator. Often you can find these listed on the embassy's website. If your degree is bilingual or in English, that is fine as well."
  },
  {
    "question": "Do I need an apostille for the marriage certificate?",
    "answer": "Yes, most embassies require both the official translation of the marriage certificate and an apostille. You can get these from the embassy or official authorities of your country of origin. The apostille will also be needed at the foreigners' department in Germany when you change the visa into a long-term residence title."
  },
  {
    "question": "When can I expect my visa to be issued after the embassy interview takes place?",
    "answer": "Usually after a few days or weeks. The embassy needs to check the data and request approval from the foreigners' department at the destination. In work-related visa cases, the department has a deadline of 10 working days to refuse the case; if they don’t react within this timeframe, the visa is approved. Often you have an answer within two weeks."
  },
  {
    "question": "How long will my visa be valid?",
    "answer": "The visa will be valid for 90 days, giving you enough time to travel to Germany, change your visa into a long-term residence title, get your address registered, and arrange German health insurance. In some cases, the embassy issues the visa for 180 days to provide a longer timeframe. If you have a flight booked already, mention this during your interview to discuss a reasonable timeframe with the officer."
  },
  {
    "question": "When my degree is not listed in the database Anabin, what should I do?",
    "answer": "If your degree or university is not listed in Anabin, you can apply for recognition through the German institution called KMK. We can assist with this process. The fee from the KMK is about €200. <a href='https://www.kmk.org/zab/central-office-for-foreign-education/statement-of-comparability-for-foreign-higher-education-qualifications.html' target='_blank'>More info</a>."
  }
]

<script>
  import { personStore as person } from '@stores';
  import { signOut } from '@api/api.js';
  import { accordion } from '@lib/Accordion.js';
  import { Icon, Link } from '@components';
  import accountIcon from '@iconify-icons/mdi/account';
  import logoutIcon from '@iconify-icons/mdi/logout';


  import { toastSuccess, toastWarning } from '@components/Toasts.svelte';

  import { ripple } from '@lib/ripple.js';

  import { navigate } from '@components/Router.svelte';

  let { mobile = false, ...rest } = $props();

  function onclick(event) {
    event.preventDefault();
    signOut();
    toastSuccess('See you next time!');
    navigate('/');
  }


</script>

<style>
  details.dropdown {
    display: inline-block;
    margin: 0;
  }

  details.dropdown ul {
    left: auto;
    right: 0;
  }

  details.dropdown summary {
    display: flex;
    align-items: center;
    overflow: hidden;

    &.mobile {
      height: 100%;
      border: 0;
    }
  }

  details.dropdown summary :global(svg) {
    margin-right: 0.25rem;
  }

  details.dropdown ul li :global(svg) {
    margin-right: 0.25rem;
  }
</style>

<details use:accordion class="dropdown" >
  <summary use:ripple role="button" class:mobile class="primary outline {{...rest}.class}"><Icon icon={accountIcon} width="1.25rem" />{if ! mobile}{$person.first_name}{/if}</summary>
  <ul>
    {#if $person}
      <li><p><strong>{$person.full_name}</strong><br />{$person.email}</p></li>
    {/if}
    <li><Link href="/account" class="secondary" icon={accountIcon}>Account</Link></li>
    <li><Link {onclick} class="secondary" icon={logoutIcon}>Sign out</Link></li>
  </ul>
</details>

<script>
  import { fade, slide } from 'svelte/transition';

  import Button from '@components/RippleButton.svelte';
  import { Icon } from '@components';

  let { active = $bindable(false), disabled = $bindable(false), icon = null, header, button, children, ...rest } = $props();

  let element;

  let prefix = 'button:';

  export function onclick(event) {
    //console.log('onclick', event);
    event?.preventDefault();
    active = !active;
  }

  function extract(map, prefix) {
    let extracted = {};
    const prefixLength = prefix.length;

    Object.keys(map).forEach(key => {
      //console.log('key: ', key, 'value: ', map[key]);
      if (key.startsWith(prefix)) {
        extracted[key.slice(prefixLength)] = map[key];
        delete map[key];
      }

    });

    return extracted;

  }

  rest = { ...rest };
  let buttonRest = extract(rest, prefix);

</script>

<style>

  div {
    overflow: hidden;
    padding: 0.1rem;

    &:not(& > div) {
      border-top: 1px var(--pico-muted-border-color) solid;

      &:first-child {
        border-top: none;
      }

    }

    h2 {

      display: block;
      margin: 0 1rem 0 0;
      font-size: 1.25rem;
      position: relative;
      cursor: pointer;
      padding: var(--pico-spacing);

      &:hover {
        --pico-color: var(--pico-primary-hover);
        animation: vibrate 1.5s infinite forwards;
      }

    }

    h2 > span, Button {
      position: relative;
      padding-right: calc(var(--pico-spacing) * 2.25);
      display: inline-flex;
    }

    h2 > span::after, Button::after {
        content: "";
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        mask: var(--pico-icon-chevron) 0 0 / contain no-repeat;
        background: var(--pico-color);
        transition: transform 0.3s ease;
        position: absolute;
        right: calc(var(--pico-spacing) * 0.5);
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
      }

    &.active {
      h2 > span::after, Button::after {
        transform: translateY(-50%) rotate(0deg);
      }
    }

    p {
      font-size: 1rem;
      padding-inline: 1rem;

    }

    Button {
      margin: 0.5rem;
      /*
      position: relative;
      overflow: hidden;
       */
    }

  }

  [disabled] {
	opacity: .5;
	pointer-events: none;
  }

</style>

<div bind:this={element} class:accordion={1} class:active={active} {disabled} {...rest}>
  {#if header}
    <h2 {onclick}><span><Icon {icon} size="1.5rem">{@render header()}</Icon></span></h2>
  {/if}
  {#if button}
    <Button {...buttonRest} {onclick}>{@render button()}</Button>
  {/if}
  {#if active}
    <p transition:slide={{ duration: 500 }}>{@render children()}</p>
  {/if}
</div>

import IconWithLabel from './IconWithLabel.svelte';
import LinkWithIcon from './LinkWithIcon.svelte';
import RippleButton from './RippleButton.svelte';

const Icon = IconWithLabel;
const Link = LinkWithIcon;
const Button = RippleButton;

export {
  Icon,
  IconWithLabel,
  Link,
  LinkWithIcon,
  Button,
  RippleButton
};

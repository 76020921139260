<script>
  let { token = undefined } = $props();

  import { navigate } from '@components/Router.svelte';
  import { signIn, fetchUserInfo } from '@api/api.js';
  import { toastSuccess, toastWarning } from '@components/Toasts.svelte';


  $effect(() => {
      console.log('SignIn mounted');
      if (!token) {
        token = new URLSearchParams(window.location.search).get('token');
      }
      if (token) {
          signIn(token).then(success => {
              if (success) {
                console.log('Sign in successful.');
                toastSuccess('Sign in successful.');
                fetchUserInfo();
                navigate('/');
              } else {
                  toastWarning('Failed to sign in.');

                //navigate('/');

              }
          });
      } else {
          console.error('Token not found in URL parameters');
          return;
      }
  });
</script>

<h1>User Sign-in</h1>

import { writable } from 'svelte/store';

//export const personStore = writable(null);
export const personStore = createLocalStorageStore('person_data', null);
export const aiSearchStore = createLocalStorageStore('ai_search_data', null);
export const authLoading = writable('initial');
export const anabinSearchStore = createLocalStorageStore('Anabin', { country: '', showTranslations: false, highlight: false }, false);
export const configStore = createLocalStorageStore('config', { theme: 'light' });

export function createLocalStorageStore(key, initialValue, updateFromLocalStorage = true) {
  // Safely get the initial value from localStorage or fallback to initialValue
  const storedValue = localStorage.getItem(key);
  const store = writable(storedValue ? JSON.parse(storedValue) : initialValue);

  // Sync with localStorage when the store changes
  store.subscribe(val => {
    const stringValue = JSON.stringify(val);
    if (localStorage.getItem(key) !== stringValue) {
      localStorage.setItem(key, stringValue);
    }
  });

  // Listen for localStorage changes in other tabs
  if (updateFromLocalStorage) {
    window.addEventListener('storage', (event) => {
      if (event.key === key) {
        if (event.newValue) {
          store.set(JSON.parse(event.newValue));
        } else {
          store.set(initialValue); // Reset to initialValue if key is removed
        }
      }
    });
  }

  // Helper function to create sub-store proxies for individual properties
  function createSubStoreForKey(prop) {
    return {
      subscribe: (run) => {
        return store.subscribe(val => run(val[prop]));
      },
      set: (newValue) => {
        store.update(current => ({
          ...current,
          [prop]: newValue
        }));
      }
    };
  }

  // Proxy to dynamically return sub-stores for individual properties
  return new Proxy(store, {
    get(target, prop) {
      if (prop === 'subscribe' || prop === 'set' || prop === 'update') {
        return target[prop]; // Return native store methods for full store access
      }
      return createSubStoreForKey(prop); // Return proxy for individual property
    }
  });
}


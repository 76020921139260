<script>
  import { fade } from 'svelte/transition';

  import reloneeLogo from '@assets/relonee_logo.svg';
  import reloneeLogoWhite from '@assets/relonee_logo_white.svg';

  import MediaQuery from '/src/components/MediaQuery.svelte';
  import MobileMenuButton from '/src/components/MobileMenuButton.svelte';
  import MobileMenu from '@components/MobileMenu.svelte';
  import Menu from '@components/layout/Menu.svelte';

  import LoginForm from '/src/components/LoginForm.svelte';
  import ContactForm from '/src/components/ContactForm.svelte';
  import UserMenu from '/src/components/UserMenu.svelte';
  import ToggleDarkMode, { darkMode } from '/src/components/ToggleDarkMode.svelte';

  import Footer from '/src/components/layout/Footer.svelte';

  import { personStore as person, authLoading } from '@stores';
  import { onMount, setContext } from 'svelte';
  import { writable } from 'svelte/store';

  import Toaster, { toastSuccess, toastWarning } from '@components/Toasts.svelte';

  let mobileMenuOpen = false;

  let contrastMode = writable(false);

  let header;

  setContext('contrastMode', contrastMode);

  //  $: console.log('contrastMode: ', $contrastMode);

  // List of navigation items
  const items = [
    { path: '/', label: 'Home' },
    { path: '/anabin', label: 'Anabin' },
    { path: '/faq', label: 'FAQ' },
    //{ path: '/demo', label: 'Demo' },
    { path: '/about', label: 'About us' },
  ];

  onMount(() => {
    /*
    authLoading.set('localstorage_loading'); // Set loading to localstorage_loading at the start
    const sessionKey = localStorage.getItem('session_key');
    const sessionId = localStorage.getItem('session_id');
    const personData = localStorage.getItem('person_data');

    if (sessionKey && sessionId && personData) {
      person.set(JSON.parse(personData));
      authLoading.set('localstorage_loaded'); // Set authLoading to localstorage_loaded once finished
    } else {
      person.set(null);
      authLoading.set('localstorage_loaded'); // Set authLoading to localstorage_loaded if no user data is available
    }
     */

    //toastSuccess('Login was successful.');
    //toastWarning('Login was successful.');

    /*
    header.addEventListener('transitionstart', (e) => {
      console.log('Transition started on', e.target);
    });

    header.addEventListener('transitionend', (e) => {
      console.log('Transition ended on', e.target);
    });
     */

  });

</script>

<style>
  header {
    animation: fadeIn 0.5s ease-in forwards;
    position: relative;
    z-index: 100;
  }

  .logo {
    margin-top: 0;
    height: 2.5rem;
    will-change: filter;
    transition: filter 300ms;
    z-index: 20;
    position: relative;
  }

  .logo:hover {
    filter: drop-shadow(0 0 2em #fff);
  }

  /*
  .menu {
    flex-grow: 1;
    justify-content: flex-end;
    font-family: Mulish;
  }
   */

  nav {
    flex-wrap: wrap;

    /*
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
     */
    user-select: none;

  }

  :root:not([data-theme="dark"]) {

    .logo:hover {
      filter: drop-shadow(0 0 2em #000);
    }

    /*
    nav.contrast ul.menu a {
      color: var(--pico-contrast);
    }

    nav.contrast ul.menu ToggleDarkMode {
      transition: none;
      color: var(--pico-contrast);
      border-color: var(--pico-contrast);
    }

    nav.contrast ul.menu a:hover {
      color: var(--pico-contrast);
      text-decoration-color: var(--pico-contrast);
      text-decoration: none;
    }

    nav.contrast ul.menu a::before {
      background-color: var(--pico-contrast);
    }
     */
  }


</style>

<header class="container" bind:this={header}>
  <nav class:contrast={$contrastMode}>
    <ul>
      <li>
        {#if $darkMode}
          <img src={reloneeLogoWhite} alt="Relonee Logo" class="logo" />
        {:else}
          <img src={reloneeLogo} alt="Relonee Logo" class="logo" />
        {/if}
      </li>
    </ul>
    <MediaQuery query="(max-width: 768px)" let:matches>
      {#if matches}
        <MobileMenu bind:open={mobileMenuOpen} {items} {contrastMode} />
      {:else}
        <Menu {items} {contrastMode} />
      {/if}
    </MediaQuery>
  </nav>
</header>

<slot></slot>

<Toaster />

<Footer />

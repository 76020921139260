<script>
  import { faqData } from './faqData.js';
  let faqs = faqData;

  let activeIndex = -1;

  function onclick(event) {
    console.log(event);
    const target = event.currentTarget;

    // Toggle the 'active' class
    if (target.classList.contains('active')) {
      target.classList.remove('active');
    } else {
      target.classList.add('active');
    }

  }

</script>

<style>
  .item {
    cursor: pointer;
    padding: 1em;
    transition: all 0.3s ease;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--pico-muted-border-color);

    &:hover {
      border: 1px solid var(--pico-primary-border);
      box-shadow: -5px 0px 5px -5px rgba(0, 0, 0, 0.2),  /* Left side shadow */
                   5px 0px 5px -5px rgba(0, 0, 0, 0.2);   /* Right side shadow */
    }

    h2 {
      display: inline-block;
      margin: 0;
      font-size: 1.25rem;
      position: relative;
      padding-right: 1.5rem;

      &::after {
        content: "";
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background-image: var(--pico-icon-chevron);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        transition: transform 0.3s ease;
      }
    }

    .answer {
      font-size: 1.1rem;
      margin: 0;
      padding: 0;
      overflow: hidden;

      display: grid;
      grid-template-rows: 0fr;
      transition: 500ms grid-template-rows ease, 500ms padding ease, 500ms box-shadow ease;

      &> div {
        overflow: hidden;
      }
    }

    &.active {
      box-shadow: -0.8rem 0px 0.8rem -0.8rem rgba(0, 0, 0, 0.1),  /* Left side shadow */
                   0.8rem 0px 0.8rem -0.8rem rgba(0, 0, 0, 0.1);   /* Right side shadow */

      .answer {
        grid-template-rows: 1fr;
        padding-top: 0.5rem;

      }

      h2::after {
        transform: translateY(-50%) rotate(0deg);
      }
    }

  }


</style>

<main class="container">
  <section>
    <h1>Visa FAQ</h1>
    {#each faqs as { question, answer }, index}
      <div class="item" class:active={0} {onclick}>
        <h2>{question}</h2>
        <div class="answer"><div>{@html answer}</div></div>
      </div>
    {/each}
  </section>
</main>

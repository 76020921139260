<script>
  import { fade } from 'svelte/transition';

  import Icon from '@components/IconWithLabel.svelte';
  import Link from '@components/LinkWithIcon.svelte';
  import successIcon from '@iconify-icons/ph/check-circle-duotone';
  import downloadIcon from '@iconify-icons/ph/download-duotone';
  import linkIcon from '@iconify-icons/ph/arrow-square-out-duotone';
  import degreeIcon from '@iconify-icons/ph/graduation-cap-duotone';
  import institutionIcon from '@iconify-icons/ph/buildings-duotone';

  import { toastSuccess, toastWarning, toastPop } from '@components/Toasts.svelte';

  let { results = [], highlight = false, showTranslations = false, searchString = '', type = 'degrees' } = $props();

  const typeDegrees = 'degrees';
  const typeInstitutions = 'institutions';

  function stripDiacritics(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  function highlightText(text, search) {
      search = search.trim();
      if (!text || !search) return text;

      // Stripped versions of text and search string to ignore accents
      const strippedText = stripDiacritics(text).toLowerCase();
      const strippedSearch = search;


      // Handle the case when no match is found
      if (!strippedText.includes(strippedSearch)) {
          return text;
      }

      // Replace in the original text based on matches in the stripped text
      let result = '';
      let lastIndex = 0;

      // Manually find and replace matching parts
      for (let i = 0; i < strippedText.length; i++) {
          const index = strippedText.indexOf(strippedSearch, i);
          if (index === -1) break;

          // Append the portion of the original text before the match
          result += text.substring(lastIndex, index);

          // Append the highlighted match
          const originalMatch = text.substring(index, index + strippedSearch.length);
          result += `<mark>${originalMatch}</mark>`;

          // Update the last index and move `i` to avoid matching the same part
          lastIndex = index + strippedSearch.length;
          i = lastIndex - 1;
      }

      // Append the rest of the original text after the last match
      result += text.substring(lastIndex);

      return result;
  }

  function highlightOtherNames(otherNames, search) {
    return otherNames.reduce((arr, value) => {
      if (value.name_type != 'name') {
        arr.push([ value.name_type, highlightText(value.name, search) ]);
      }
      return arr;
    }, []);
  }

  function getDownloadUrl(id) {
    if (type === typeDegrees) {
      return `${window.config.apiBaseUrl}/degrees/download/${id}/`;
    } else if (type === typeInstitutions) {
      return `${window.config.apiBaseUrl}/institutions/download/${id}/`;
    }
    return '#'; // fallback URL
  }

  function getDegreeSearchUrl(id) {
    const param = encodeURIComponent(`institution:${id}`);
    return `${window.config.baseUrl}/anabin?degrees=${param}`;
  }

  function getInstitutionSearchUrl(id) {
    const param = encodeURIComponent(`degree:${id}`);
    return `${window.config.baseUrl}/anabin?institutions=${param}`;
  }


  let errorMsg;
  let successMsg;

  async function downloadClick(event) {
    event.preventDefault(); // Prevent the default link behavior
    console.log('downloadClick', event);

    const url = event.currentTarget.href;

    try {
      // Show the success toast
      successMsg = toastSuccess('Download will begin shortly');
      if (errorMsg) toastPop(errorMsg);

      // Fetch the file
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Download failed: ${response.status} ${response.statusText}`);
      }

      // Get the filename from the Content-Disposition header
      const disposition = response.headers.get('Content-Disposition');
      let filename = 'downloaded-file';
      if (disposition && disposition.includes('attachment')) {
        const match = disposition.match(/filename="?([^"]+)"?/);
        if (match && match[1]) {
          filename = match[1];
        }
      }
      console.log('Download: ', filename, 'disp: ', disposition);

      // Convert the response to a Blob
      const blob = await response.blob();

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);

      console.log('Download finished');
      toastPop(successMsg);

    } catch (error) {
      console.error(error);
      toastPop(successMsg);
      errorMsg = toastWarning(`${error.message}`);
    }
  }

  function processResults() {
    let search = stripDiacritics(searchString).toLowerCase();
    search = search.replace(/city:\s?[^\s]+/, '');
    return results.map(result => ({
        ...result,
        highlightedName: highlightText(result.name, search),
        highlightedOtherNames: highlightOtherNames((type == 'degrees' ? result.degree_names : result.institution_names), search)
    }));
  }

  let processedResults = $derived(processResults());

</script>

<style>
  table {

    border-collapse: collapse;
    line-height: 1.2rem;
    overflow-x: auto;
    /*
    border-spacing: 1rem;
    table-layout: fixed;
     */

    :global(mark) {
      padding: 0;
      background: var(--pico-muted-border-color);
      color: var(--pico-primary);
      font-weight: bold;
    }

    th {
      padding: 0.75rem 0;
      position: sticky;
      top: 0;
      box-shadow: inset 0 -1px 0 0 var(--pico-primary);
      border: 0;

      &:first-child {
        padding-left: 0.5rem;
      }

      &:last-child {
        padding-right: 0.5rem;
      }

      &:nth-child(1) {
        width: 25%;
      }

      &:nth-child(2) {
        width: 10%;

      }
      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(4) {
        width: 15%;
      }

      &:nth-child(5) {
        width: 15%;
      }
      &:nth-child(6) {
        width: 10%;
      }

    }

    td {
      padding: 1rem 1rem 1rem 0;
      vertical-align: top;

      &:first-child {
        padding-left: 0.5rem;
      }

      &:last-child {
        padding-right: 0.5rem;
      }

      &.links {
        line-height: 1.3rem;
      }

      Icon {
        color: var(--pico-primary);
      }

      dl {
        padding: 0;
        margin: 0;
        padding: 0.3rem 0 0 0;

        dt {
          color: var(--pico-muted-color);
          line-height: 0.8rem;
          font-size: 80%;
          margin: 0.3rem 0 0 0;

          &:first-child {
            margin-top: 0;
          }
        }

        dd {
          margin: 0;
        }
      }

    }
  }
  table.institutions {
    th {
      &:nth-child(1) {
        width: 30%
      }

      &:nth-child(2) {
        width: 15%;

      }
      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(4) {
        width: 20%;
      }

      &:nth-child(5) {
        width: 10%;
      }
    }
  }
</style>

<table class="results striped {type}">
  <thead>
    <tr>
      <th class="name-column">Name</th>
      {#if type == typeInstitutions}
        <th>Location</th>
        <th>Status</th>
        <th>Type</th>
      {:else}
        <th>Country</th>
        <th>Degree Type</th>
        <th>Study Field</th>
        <th>Evaluation</th>
      {/if}
      <th>Links</th>
    </tr>
  </thead>
  <tbody>
    {#each processedResults as result, i}
      <tr>
        <td class="name-column">
          {@html result.highlightedName}
          {#if showTranslations && (result.highlightedOtherNames || []).length > 0}
            <dl>
              {#each result.highlightedOtherNames as [language, translation]}
                <dt>{language}</dt>
                <dd>{@html translation}</dd>
              {/each}
            </dl>
          {/if}
        </td>
        {#if type == typeInstitutions}
          <td>{#if result.city_name}{result.city_name},<br />{/if}{result.country_name}</td>
          <td>
            {#if result.status == 'H+'}
              <Icon icon={successIcon}>Recognized ({result.status})</Icon>
            {:else}
              Not recognized ({result.status})
            {/if}
          </td>
          <td>{result.institution_type}</td>
        {:else}
          <td>{result.country_name}</td>
          <td>{result.degree_type}</td>
          <td>
            {result.study_field}
            {#if showTranslations && result.study_field_german_translation}
              <dl><dt>german</dt><dd>{result.study_field_german_translation}</dd></dl>
            {/if}
          </td>
          <td>
            {#if Object.entries(result.evaluations) != ''}
               <Icon icon={successIcon}>Recognized</Icon>
            {:else}
              Not recognized
            {/if}
            <dl>
              {#if showTranslations && Object.entries(result.evaluations) != ''}
                {#each result.evaluations as evaluation}
                  <dt>{evaluation.equivalence_class}</dt>
                  <dd>{evaluation.corresponding_german_degree_type}</dd>
                {/each}
              {/if}
            </dl>
          </td>
        {/if}
        <td class="links">
          <Link href={getDownloadUrl(result.id)} download onclick={downloadClick} icon={downloadIcon}>Download</Link>
          <Link href={result.source_url} blank icon={linkIcon} rel="noopener noreferrer">Anabin</Link>
          {#if result.homepage}
            <Link href={result.homepage} blank icon={linkIcon} rel="noopener noreferrer">Homepage</Link>
          {/if}
          {#if result.has_degrees > 0}
            <Link href={getDegreeSearchUrl(result.id)} icon={degreeIcon}>{#if result.has_degrees > 1}Degrees&nbsp;({result.has_degrees}){:else}Degree&nbsp;(1){/if}</Link>
          {/if}
          {#if result.has_institutions > 0}
            <Link href={getInstitutionSearchUrl(result.id)} icon={institutionIcon}>{#if result.has_institutions > 1}Institutions&nbsp;({result.has_institutions}){:else}Institution&nbsp;(1){/if}</Link>
          {/if}
        </td>
      </tr>
    {/each}
  </tbody>
</table>


<script>
  import Dialog from './Dialog.svelte';
  import Button from './RippleButton.svelte';
  import Icon from '@iconify/svelte';
  import loginIcon from '@icons/ph/sign-in-duotone';

  import { submitSigninForm } from '@api/api.js';
  import { toastSuccess, toastWarning } from '@components/Toasts.svelte';

  import { loadRecaptcha } from '@lib/utils.js';
  import autofocus from '@lib/autofocus.js';

  let dialog;

  // Form input values
  let email = '';
  let terms = false;

  // Error messages
  let emailError = '';
  let emailInvalid;
  let termsError = '';
  let termsInvalid;

  let formError = '';

  let submitted = false;
  let recaptchaToken = '';

  function validateForm() {
    // Reset error messages
    emailError = '';
    termsError = '';

    // Validate form fields
    emailInvalid = !(/^\S+@\S+\.\S+$/.test(email));
    termsInvalid = !terms;

    if (emailInvalid) emailError = 'Please provide a valid email address';
    if (termsInvalid) termsError = 'You need to agree to the terms';

    return !(emailInvalid || termsInvalid);
  }

  async function onsubmit(event) {
    event.preventDefault();
    formError = '';
    submitted = true; // Set submitted to true when form submission starts

    try {
      if (!validateForm()) {
        submitted = false;
        return;
      }

      if (!recaptchaToken) {
        // If reCAPTCHA is not loaded, load it dynamically
        const grecaptcha = await loadRecaptcha(window.config.recaptchaKey, window.config.nonce);
        recaptchaToken = await grecaptcha.execute(window.config.recaptchaKey, { action: 'contact_form' });
      }

      // Call the function from api.js to handle form submission
      const response = await submitSigninForm({ email });
      if (response.error) {
        console.error('Failed to sign in:', response.error);
        formError = response.error.message || 'Failed to sign in';
        submitted = false; // Reset submitted if there's an error
        return;
      }
      toastSuccess('Sign in email requested. Check your inbox!');
      dialog.close();

    } catch (error) {
      console.error('Failed to send message:', error);
      submitted = false; // Reset submitted on error
    }
  }

  function onclick(event) {
    event.preventDefault();
    dialog.show(event);

    // Load reCAPTCHA when the modal is shown
    loadRecaptcha(window.config.recaptchaKey, window.config.nonce).then(grecaptcha => {
      grecaptcha.ready(() => {
        console.log('reCAPTCHA ready');
      });
    }).catch(error => {
      console.error('Error loading reCAPTCHA:', error);
    });
  }

</script>

<style>
  /*
  Button {
    --pico-nav-link-spacing-horizontal: 1rem;
  }
   */
  label.checkbox {
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    input {
      margin-top: 0.15rem;
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;
      li {
        list-style: none
      }

      li.small, li.small a {
        font-size: 0.9rem;
        color: var(--pico-muted-color);
        text-decoration-color: var(--pico-muted-color);
      }

      li.error {
        font-size: 0.9rem;
        color: var(--pico-del-color);
      }
    }
  }
</style>


<slot {onclick}>
  <Button {onclick}>Sign in</Button>
</slot>

<Dialog bind:this={dialog} {onsubmit} novalidate>
  <h2 slot="header">Sign in</h2>
  <label>
    Your email address:
    <input type="email" bind:value={email} name="email" placeholder="your@email.com" use:autofocus aria-invalid={emailInvalid} disabled={submitted} />
    {#if emailError}<small>{emailError}</small>{/if}
  </label>
  <label class="checkbox">
    <input type="checkbox" bind:checked={terms} name="terms" aria-invalid={termsInvalid} disabled={submitted} />
    <ul>
      <li>I hereby accept Relonee's <a href="/privacy" target="_blank">Privacy Policy</a> for processing and<br />storing this form's data.</li>
      <li class="small">This site is protected by reCAPTCHA and the Google.<br />
        <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
        <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
      </li>
      {#if termsError}<li class="error">{termsError}</li>{/if}
    </ul>
  </label>
  {#if formError}
    <p class="error-message">{formError}</p>
  {/if}
  <footer>
    <Button type="submit" default aria-busy={submitted} disabled={submitted} icon={loginIcon}>Sign in</Button>
    <Button class="outline" type="cancel" onclick={(event) => dialog.close(event)}>Cancel</Button>
  </footer>
</Dialog>

export default function autofocus(node, { filter } = {}) {
  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      const activeElement = document.activeElement;

      // Check if there is an active element and if it matches the filter selector
      if (!filter || !activeElement || !activeElement.matches(filter)) {
        node.focus(); // Only focus if the filter doesn't match
      }
    }
  });

  // Start observing the node
  observer.observe(node);

  return {
    destroy() {
      // Cleanup when the element is destroyed
      observer.disconnect();
    }
  };
}

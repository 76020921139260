<script>
  import { Button } from '@components';

  let emailError = '';
  let emailInvalid;
  let formError = '';

  let submitted = false;

  let recaptchaToken = '';

</script>

<style>

  form {
    max-width: 32rem;
  }

</style>

<main class="container">
  <h1>Getting Started with RELONEE</h1>

  <p>Welcome to RELONEE! We're here to make relocating to Germany as smooth as possible for your company and your employees. Our AI-driven platform takes the hassle out of dealing with German bureaucracy, so you can focus on what matters most—getting your new team members up and running.</p>

  <h2>What We Do</h2>

  <p>At RELONEE, we specialize in simplifying the relocation process for international employees moving to Germany. From work visas and accommodation to health insurance and government registration, we’ve got you covered.</p>

  <p>Here’s how we help:</p>
  <ul>
    <li><strong>Work Visa</strong>: We manage all paperwork and guide you through the entire visa application process.</li>
    <li><strong>Accommodation</strong>: We assist in finding a temporary boarding house or long-term housing.</li>
    <li><strong>Health Insurance</strong>: We arrange the necessary health insurance coverage for your employees.</li>
    <li><strong>Government Registration</strong>: We ensure a smooth registration process with German authorities (coming soon).</li>
  </ul>

  <h2>Join Our Waitlist</h2>

  <p>Currently, we’re perfecting our services and are operating a waitlist. By registering now, you will be the first to experience our seamless relocation platform once we launch fully.</p>

  <h3>Why Join the Waitlist?</h3>

  <ul>
    <li>Be the first to get access to our all-in-one AI-driven relocation platform.</li>
    <li>Receive exclusive updates and tips on navigating the relocation process to Germany.</li>
    <li>Secure a spot and be ready to streamline your relocation needs when we go live.</li>
  </ul>

  <form>
    <fieldset role="group">
      <input type="email" name="email" aria-invalid={emailInvalid} disabled={submitted} placeholder="E-mail" />
      <input type="submit" value="Register for Waitlist" />
      {#if emailError}<small>{emailError}</small>{/if}
    </fieldset>
  </form>

  <h2>How It Works</h2>

  <ol>
    <li><strong>Sign up for the waitlist</strong>: Fill out our quick form and reserve your spot.</li>
    <li><strong>Stay informed</strong>: We’ll keep you in the loop with important updates and launch information.</li>
    <li><strong>First access</strong>: When we’re ready, you’ll be the first to experience our platform and start your employees' relocation process.</li>
  </ol>

</main>


<script>
  import { ripple } from '@lib/ripple.js';
  import Icon from '@components/IconWithLabel.svelte';

  let { onclick, children, icon, iconSize = '1.25rem', ...rest } = $props();

  let buttonElement = $state();
  let rippleVisible = $state(false);
  let rippleStyle = $state();
  let rippleTimeout;

  function rippleEffect(event) {
    //console.log('Create the ripple');
    const rect = buttonElement.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = event.clientX - rect.left - size / 2;
    const y = event.clientY - rect.top - size / 2;

    // Clear the previous timeout if it exists
    if (rippleTimeout) {
      rippleVisible = false;
      clearTimeout(rippleTimeout);
    }

    // Update the ripple style and visibility
    rippleStyle = `width: ${size}px; height: ${size}px; left: ${x}px; top: ${y}px;`;
    requestAnimationFrame(() => {
      rippleVisible = true;
    })

    // Hide the ripple after the animation duration
    rippleTimeout = setTimeout(() => {
      rippleVisible = false;
    }, 600); // Duration of the ripple effect

    // If the user provided an onclick handler, call it
    if (onclick) {
      //console.log('Forward the click');
      onclick(event);
    }
  }
</script>

<style>
  button {
    position: relative;
    overflow: hidden;
    /*
    display: inline-flex;
    align-items: center;
    justify-content: center;
     */

    :global(svg) {
      margin-right: 0.25em;
    }
    /*flex: 1;*/
  }

  .ripple {
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    transform: scale(0);
    animation: ripple-animation 0.6s linear;
    /* Let clicks pass through to the button */
    pointer-events: none;
  }

  @keyframes ripple-animation {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }

</style>

<button use:ripple bind:this={buttonElement} {onclick} {...rest} >
  {#if icon}
    <Icon {icon} size={iconSize} {children} />
  {:else}
    {@render children?.()}
  {/if}
</button>

<script>
  //import { fade } from 'svelte/transition';

  import reloneeLogo from '@assets/relonee_logo.svg';
  import reloneeLogoWhite from '@assets/relonee_logo_white.svg';

  import MediaQuery from '@components/MediaQuery.svelte';
  import MobileMenuButton from '@layout/MobileMenuButton.svelte';
  import MobileMenu from '@layout/MobileMenu.svelte';
  import Menu from '@layout/Menu.svelte';

  import ToggleDarkMode from '@components/ToggleDarkMode.svelte';

  import AccountLayout from '@layout/Account.svelte';

  import Footer from '@layout/Footer.svelte';

  import { personStore as person, authLoading } from '@stores';
  import { onMount, setContext } from 'svelte';
  import { writable } from 'svelte/store';
  import { configStore as config } from '@stores';

  import Toaster, { toastSuccess, toastWarning } from '@components/Toasts.svelte';

  import { renderSnippetToHTML } from '@lib/utils.js';

  let { children } = $props();

  let mobileMenuOpen = $state(false);
  let contrastMode = writable(false);

  let header;

  setContext('contrastMode', contrastMode);

  $effect(() => {
    console.log('contrastMode effect: ', $contrastMode);
  });

  // List of navigation items
  const items = [
    { path: '/', label: 'Home' },
    { path: '/anabin', label: 'Anabin' },
    { path: '/faq', label: 'FAQ' },
    //{ path: '/demo', label: 'Demo' },
    { path: '/about', label: 'About us' },
  ];

  let useAccountLayout = $state();

  /*
  // Update the currentPath store when the URL changes
  function updateChildLayout(event) {
    const path = event ? event?.detail?.path : window.location.pathname;
    //TODO: Strange behaviour when browsing away from Signin with duplicate rendering
    useAccountLayout = path.startsWith('/account');
    //console.log('Update child layout', event, useAccountLayout);
  }
  updateChildLayout();

  // Listen for popstate and pushstate events
  onMount(() => {

    window.addEventListener('beforeNavigate', updateChildLayout);
    //window.addEventListener('popstate', updateChildLayout);
    //window.addEventListener('pushstate', updateChildLayout);

    return () => {
      window.removeEventListener('beforeNavigate', updateChildLayout);
      //window.removeEventListener('popstate', updateChildLayout);
      //window.removeEventListener('pushstate', updateChildLayout);
    };
  });
   */

</script>

<style>
  header {
    animation: fadeIn 0.5s ease-in forwards;
    position: relative;
    z-index: 100;
  }

  .logo {
    margin-top: 0;
    height: 2.5rem;
    will-change: filter;
    transition: filter 300ms;
    z-index: 20;
    position: relative;
  }

  .logo:hover {
    filter: drop-shadow(0 0 2em #fff);
  }

  nav {
    flex-wrap: wrap;
    user-select: none;

    & > ul:first-child {
      flex-grow: 1;
    }

  }

  :root:not([data-theme="dark"]) {
    .logo:hover {
      filter: drop-shadow(0 0 2em #000);
    }
  }


</style>

<MediaQuery query="(max-width: 768px)" let:matches>
  {#if matches}
    <MobileMenu bind:open={mobileMenuOpen} {items} {$contrastMode} />
  {/if}
<header class="container" bind:this={header}>
  <nav class:contrast={$contrastMode}>
    <ul>
      <li>
        {#if $config.darkMode}
          <img src={reloneeLogoWhite} alt="Relonee Logo" class="logo" />
        {:else}
          <img src={reloneeLogo} alt="Relonee Logo" class="logo" />
        {/if}
      </li>
    </ul>
    {#if matches}
      <MobileMenuButton bind:open={mobileMenuOpen} {$contrastMode} />
    {:else}
      <Menu {items} {$contrastMode} />
    {/if}
  </nav>
</header>
</MediaQuery>

{@render children()}

<Toaster />

<Footer />

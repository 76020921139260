<script>
  let { active = $bindable(), children, ...props } = $props();

  //const defaultStyle = "hamburger--emphatic";
  //const defaultStyle = "hamburger--elastic";
  const defaultStyle = "hamburger--spin";

  function onclick(event) {
    console.log('Click hamburger button: ', active);
    active = !active;
  }
</script>
<style>

  /*!
   * Hamburgers
   * @description Tasty CSS-animated hamburgers
   * @author Jonathan Suh @jonsuh
   * @site https://jonsuh.com/hamburgers
   * @link https://github.com/jonsuh/hamburgers
   */
  .hamburger,
  .hamburger:focus {
    display: inline-flex;
    align-items: center;
    /*padding: 15px 15px;*/
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    /*
    font: inherit;
    color: inherit;
    text-transform: none;
     */
    background-color: transparent;
    border: 0;
    margin: 0;
    box-shadow: none;
    overflow: visible;
    z-index: 20;

    /* Compensate for breakpoint padding on <header> */
    margin-right: calc(var(--pico-nav-element-spacing-horizontal) * -1);
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: var(--pico-background-color);
  }

  /*
  .hamburger.is-active .hamburger-inner:hover,
  .hamburger.is-active .hamburger-inner::before:hover,
  .hamburger.is-active .hamburger-inner::after:hover {
    background-color: var(--pico-background-color);
  }
   */

  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: var(--pico-background-color);
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }

  /*
  .hamburger--elastic .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
  transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

  .hamburger--elastic.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
  opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
  transition-delay: 0.075s; }

  .hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in; }
  .hamburger--emphatic .hamburger-inner::before {
    left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
  .hamburger--emphatic .hamburger-inner::after {
    top: 10px;
    right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
  background-color: transparent !important; }
  .hamburger--emphatic.is-active .hamburger-inner::before {
    left: -80px;
    top: -80px;
    transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
  .hamburger--emphatic.is-active .hamburger-inner::after {
    right: -80px;
    top: -80px;
    transform: translate3d(-80px, 80px, 0) rotate(-45deg);
    transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

   */

  .hamburger--spin .hamburger-inner {
    transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

  .hamburger--spin.is-active .hamburger-inner {
    transform: rotate(225deg);
    transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }


</style>


<button class="hamburger {defaultStyle}" class:is-active={active} type="button" {onclick}>
  <span class="hamburger-box">
    <span class="hamburger-inner"></span>
  </span>
</button>

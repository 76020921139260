<script>
  import { onMount, onDestroy } from 'svelte';
  import Button from '@components/RippleButton.svelte';

  export let showCloseButton = false;

  // Config
  const isOpenClass = "modal-is-open";
  const openingClass = "modal-is-opening";
  const closingClass = "modal-is-closing";
  const animationDuration = 300; // ms
  const html = document.documentElement;

  export let dialog;

  export let onsubmit;

  //export let icon;
  let timeout = null;

  export function show(event) {
    //console.log('show()', event, dialog);
    event?.preventDefault();
    clearTimeout(timeout);
    html.classList.remove(closingClass);
    dialog.showModal();
    html.classList.add(isOpenClass, openingClass);
    timeout = setTimeout(() => {
      html.classList.remove(openingClass);
    }, animationDuration);
    updateWidth();
  }

  export function updateWidth() {
    const article = dialog.firstElementChild;
    //console.log('Dialog article width: ', dialog, article, article.offsetWidth);
    article.style.width = `${article.offsetWidth}px`;
    //article.style.maxWidth = "none";
    //console.log('Dialog article result: ', article.style.width, article.style.maxWidth);
  }

  export function hide(event) {
    console.log('hide()', event, dialog);
    //clicked inside, so ignore
    if (event.target != dialog) {
      return;
    }
    event?.preventDefault();
    close(event);
  }

  export function close(event) {
    console.log('close()', event);
    event?.preventDefault();
    clearTimeout(timeout);
    html.classList.remove(openingClass);
    html.classList.add(closingClass);
    timeout = setTimeout(() => {
      html.classList.remove(closingClass, isOpenClass);
      dialog.close();
    }, animationDuration);
  }

  function keypress(event) {
    if (event.key === 'Escape') {
      event?.preventDefault();

      close(event);
    }
    event.stopPropagation()
  }

  onMount(() => {
    document.body.appendChild(dialog);
  });

  onDestroy(() => {
    clearTimeout(timeout);
    html.classList.remove(openingClass, closingClass, isOpenClass);
    dialog?.remove();
  });

  function onclick(event) {
    console.log('onclick() from Dialog.svelte');
    show(event);
  }

  function submit(event) {
    console.log('submit()', event);
  }

</script>

<style>
  dialog article {
    max-width: var(--max-width, max-content);
    width: auto;
    border-radius: calc(var(--pico-border-radius) * 3);
    --pico-block-spacing-horizontal: calc(var(--pico-spacing) * 1.25);
    --pico-block-spacing-vertical: calc(var(--pico-spacing) * 1);
    overflow: hidden;
    max-height: unset;

    margin: auto 0;

    :global(input:not([type="checkbox"], [type="radio"]), select, textarea) {
        margin-bottom: calc(var(--pico-spacing) * 0.5);
    }

    :global(:where(input, select, textarea) + small) {
        margin-top: calc(var(--pico-spacing) * -.35);
    }

    :global(input[type="checkbox"] + small) {
        margin-top: calc(var(--pico-spacing) * 0);
    }

    button[rel="prev"] {
      width: 1.5rem;
      height: 1.5rem;
      background-size: auto 1.5rem;
      opacity: 1;
      margin-top: 0.25rem;
    }

    header {
      padding: var(--pico-block-spacing-vertical) var(--pico-block-spacing-horizontal);
      :global(h2) {
        --pico-font-size: 1.5rem;
      }
    }

    :global(footer) {
	  padding: calc(var(--pico-block-spacing-vertical) * 1) var(--pico-block-spacing-horizontal);
      margin-block: 0 calc(var(--pico-block-spacing-vertical) * -1);
      margin-inline: calc(var(--pico-block-spacing-horizontal) * -1);
	  background-color: var(--pico-card-sectioning-background-color);
      border-top: var(--pico-border-width) solid var(--pico-card-border-color);
      /*
      border-bottom-right-radius: var(--pico-border-radius);
      border-bottom-left-radius: var(--pico-border-radius);
       */
      text-align: right;
      display: flex;
      flex-direction: row-reverse;
      gap: calc(var(--pico-form-element-spacing-horizontal) * 1);
    }
  }


  dialog article :global(footer button) {
    line-height: 1.25;
    font-weight: 600;
    padding: var(--pico-form-element-spacing-vertical) var(--pico-form-element-spacing-horizontal);
  }
  dialog article :global(footer button[type="submit"]) {
	width: auto;
    margin-bottom: 0;
  }

</style>

<dialog bind:this={dialog} on:close={close} on:click={hide} on:keydown={keypress}>
  <article {...$$restProps}>
    <header>
      <button rel="prev" on:click={close}></button>
      <slot name="header" />
    </header>
    <form method="dialog" on:submit={onsubmit}>
      <slot />
      {#if showCloseButton}
        <footer><Button onclick={close} autofocus>Close</Button></footer>
      {/if}
    </form>
  </article>
</dialog>

<slot name="trigger" {onclick}></slot>

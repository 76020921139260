<script>
  import Dropzone from "svelte-file-dropzone";
  import { Icon, Button } from "@components";
  import { uploadFile, anabinAiSearch } from "@api/api.js";

  import fileUploadIcon from '@icons/ph/upload-duotone';

  import { aiSearchStore as searchState } from '@stores';

  import Accordion from '@components/Accordion.svelte';

  import oneIcon   from '@icons/ph/number-circle-one-fill';
  import twoIcon   from '@icons/ph/number-circle-two-fill';
  import threeIcon from '@icons/ph/number-circle-three-fill';

  let file = $state();
  let uploadProgress = $state(0);
  let uploading = $state(false);
  let processing = $state(false);
  let message = $state('');

  const defaultState = [
    {'active': true},
    {'disabled': true},
    {'disabled': true},
  ];

  if (!$searchState?.steps) {
    searchState.update(current => {
      if (!current) {
        current = {};
      }
      current['steps'] = defaultState;
      return current;
    });
  }

  $effect(() => {
    //console.log('searchState', $searchState);
    //console.log('file', file);
  });

  async function handleFilesSelect(e) {
    const files = e.detail.acceptedFiles;
    console.log('handleFiles: ', files);

    if (files.length > 0) {
      file = files[0]
    }

    // Assuming you only allow single file uploads
    if (file) {
      let response;
      uploading = true;
      try {
        response = await uploadFile(file, updateProgress);
        console.log("File uploaded successfully:", response);
      } catch (error) {
        console.error("File upload failed:", error);
      } finally {
        uploading = false;
        processing = false;
        uploadProgress = 0;
      }

      if (response) {
        console.log('Response: ', response);
        searchState.update(current => {
          if (!current) {
            current = {};
          }
          current['response'] = response;
          current['steps'][0].active = false;
          current['steps'][1].active = true;
          current['steps'][1].disabled = false;
          return current;
        });
      }
    }
  }

  async function updateProgress(loaded, total) {
    uploadProgress = Math.round((loaded / total) * 100);
    if (uploadProgress == 100) {
      console.log('File upload progress: ', uploadProgress);
      processing = true;
    }
  }

  function reset(event) {
    console.log('reset', event);
    file = undefined;
    searchState.update(current => {
      if (!current) {
        current = {};
      }
      current['response'] = undefined;
      current['steps'][0].active = true;
      current['steps'][1].active = false;
      current['steps'][1].disabled = true;
      current['steps'][2].active = false;
      current['steps'][2].disabled = true;
      return current;
    });
  }

  async function onclick(event) {
    console.log('onclick', event);
    const [ result, data ] = await anabinAiSearch();
    if (!result) {
      message = data;
    }
    console.log('Result', result, data);
  }

</script>

<style>
  div.dropzone {
    max-width: 36rem;

    Dropzone {
      background-color: var(--pico-dropzone-background-color);
      border: calc(var(--pico-border-width) * 4) dotted var(--pico-h4-color);
      border-radius: 1.25rem;

      :global(&:hover) {
        background-color: var(--pico-dropzone-background-hover-color);
        border-color: var(--pico-primary-hover);

        div.dropzone-inner {
          color: var(--pico-primary-hover);
        }
      }

    }

    div.dropzone-inner {
      margin: 2rem;
      font-size: 120%;
      color: var(--pico-h4-color);
      text-align: left;

      Icon {
        margin-right: 1rem;
      }
    }
  }

  article {
    display: inline-block;
  }

  progress {
    margin-top: 1rem;
  }

  /*
  Accordion Button {
    float: right;
    margin-right: var(--pico-form-element-spacing-horizontal);
  }
   */

</style>

<h3>How it works</h3>
<section>
  <Accordion icon={oneIcon} { ... $searchState.steps[0] }>
    {#snippet header()}Upload a file with your degree{/snippet}
    <div class="dropzone" multiple={false} accept="image/jpeg, image/png, application/pdf" >
      <Dropzone on:drop={handleFilesSelect} class="dropzone">
          <div class="dropzone-inner">
            <Icon icon={fileUploadIcon} size="4rem">Upload a pdf or photo of your degree<br /><small>File types *.pdf, *.png, *.jpg</small></Icon>
          </div>
      </Dropzone>
      {#if processing}
        <progress />
        <p>Processing {file.name}</p>
      {:else if uploading}
        <progress value={uploadProgress} max="100"></progress>
        <p>Uploading {file.name}: {uploadProgress}%</p>
      {/if}
    </div>
  </Accordion>
  <Accordion icon={twoIcon} { ... $searchState.steps[1] }>
    {#snippet header()}Our AI extracts all necessary data{/snippet}
    <article>
      {#if $searchState?.response}
        We've extracted the following data from the degree:
        <table><tbody>
          {#each Object.entries($searchState?.response) as [key, value]}
              <tr><td>{key}:</td><td>{value}</td></tr>
          {/each}
        </tbody></table>
        <footer>
          <Button {onclick}>Continue</Button>
          <Button type="reset" onclick={reset}>Reset</Button>
          {#if message}{message}{/if}
        </footer>
      {:else}
        none: {$searchState} -- {$searchState?.response}
      {/if}

    </article>
  </Accordion>
  <Accordion icon={threeIcon} { ... $searchState.steps[2] }>
    {#snippet header()}Get a match from the Anabase database{/snippet}
    Nothing here yet...
  </Accordion>
</section>

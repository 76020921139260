<script context="module">
  import { get, writable } from 'svelte/store';
  import Button from './RippleButton.svelte';
  import Icon from '@iconify/svelte';
  import sunIcon from '@iconify-icons/fa-solid/sun';
  import moonIcon from '@iconify-icons/fa-solid/moon';

  export let darkMode = writable(window.config.darkMode);

  const rotateClass = writable('');

  function toggleDarkMode(event) {
    rotateClass.set('rotate-icon');
    setTimeout(() => rotateClass.set(''), 500); // Adjust the timeout to match your animation duration
    darkMode.update(isDark => {
      const newMode = !isDark;
      console.log('Toggle dark mode: ', newMode);
      document.documentElement.setAttribute('data-theme', newMode ? 'dark' : 'light');
      //The 'theme' setting is read from localStorage in `main.js` and
      //then set on `window.config.darkMode`
      localStorage.setItem('theme', newMode ? 'dark' : 'light');
      return newMode;
    });
  }

  // Apply initial theme on load
  if (darkMode) {
    document.documentElement.setAttribute('data-theme', 'dark');
  } else {
    document.documentElement.setAttribute('data-theme', 'light');
  }
</script>

<script>
  let { ...rest } = $props();
  $effect(() => {
    // Apply darkMode on changes
    if ($darkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
    }
  });
</script>

<style>
  :global(.rotate-icon) {
    animation: rotate-fade-animation 0.5s ease-in-out;
  }

  @keyframes rotate-fade-animation {
    0% {
      transform: rotate(0deg);
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      transform: rotate(360deg);
      opacity: 1;
    }
  }

  /*
  Button {
    color: green;
    background-color: white;
  }

  Button.special {
    background-color: yellow;
  }
  */

  Button .icon {
    margin: 0.25rem;
  }
</style>

<Button class="secondary outline special" {...rest} onclick={toggleDarkMode}>
  {#if $darkMode}
    <Icon icon={sunIcon} class={`icon ${$rotateClass}`} />
  {:else}
    <Icon icon={moonIcon} class={`icon ${$rotateClass}`} />
  {/if}
</Button>

<!-- Bug fix for Svelte inserting *{} at the end of styles and then complaining that * is not being used. -->
{#if 0}<span></span>{/if}

<script>
  import Layout from '../components/Layout.svelte';
  import Mini from '../components/Mini.svelte';

  import Accordion from '@components/Accordion.svelte';
  import Button from '@components/RippleButton.svelte';

  import { ripple } from '@lib/ripple.js';

  import ComboBox from '@components/ComboBox.svelte';
  import { countriesForCombobox as countries } from '@lib/countries.js';

  import { Icon } from '@components';
  import homeIcon from '@icons/mdi/home'
  import oneIcon from '@icons/ph/number-circle-one-fill';
  import twoIcon from '@icons/ph/number-circle-two-fill';

  let buttonClicked;
  let country;

  let formInput;
  let formSubmitted;

  function onclick(event) {
    buttonClicked = event.target.innerText;
  }

  function onsubmit(event) {
    console.log('onsubmit', event);
    event.preventDefault();
    formSubmitted = event.target.data;
  }

  let testRest = {class:'bold'};

</script>

<style>
  ComboBox {
    .flag {
      font-size: 1.75rem;
      height: 1.75rem;
      font-family: Arial;
    }
  }
  button {
    position: relative;
    overflow: hidden;
  }

  article.accordion {
    max-width: 30rem;
  }

  .red {
    color: red
  }

  .bgblue {
    background-color: blue;
  }

  .bold {
    font-weight: bold;
  }
</style>

<main class="container">

  <h1>Component Demo</h1>

  <section>
    <Mini>
      {#snippet slot1(label)}
        <strong>{label}</strong>
      {/snippet}
    </Mini>
  </section>

  <section>
    <h2>Buttons</h2>
    <button use:ripple {onclick}>Plain button</button>
    <button use:ripple {onclick} class="primary">Primary button</button>
    <button use:ripple {onclick} class="secondary">Secondary button</button>
    <button use:ripple {onclick} class="outline">Outline button</button>

    Clicked: {buttonClicked}
  </section>

  <section>
    <h2>ComboBox</h2>
    <ComboBox name="country" placeholder="All countries" bind:value={country} options={countries}>
      <svelte:fragment slot="option" let:option>
        <span class="flag">{option.flag}</span>
        <span>{option.text}</span>
      </svelte:fragment>
    </ComboBox>

    Selected: {country}

  </section>

  <section>
    <h2>Form submission</h2>
    <form {onsubmit}>
      <input type="text" bind:value={formInput} />
      <button type="submit">Submit</button>
    </form>

    Submitted: {formSubmitted}
  </section>

  <section>
    <h2>Accordion</h2>
    <article class="accordion">
      <Accordion class="demo">
        {#snippet header()}Simple Accordion{/snippet}
        Flamingos are known for their bright pink feathers and distinctive long necks. These birds are social creatures that live in large groups, and a group of flamingos is called a flamboyance. They can often be seen standing on one leg, which helps them conserve body heat.
      </Accordion>
      <Accordion class="demo">
        {#snippet header()}Simple Accordion 2{/snippet}
        Flamingos are known for their bright pink feathers and distinctive long necks. These birds are social creatures that live in large groups, and a group of flamingos is called a flamboyance. They can often be seen standing on one leg, which helps them conserve body heat.
      </Accordion>
      <Accordion class="demo" icon={homeIcon}>
        {#snippet header()}Accordion with Icon{/snippet}
        Flamingos are known for their bright pink feathers and distinctive long necks. These birds are social creatures that live in large groups, and a group of flamingos is called a flamboyance. They can often be seen standing on one leg, which helps them conserve body heat.
      </Accordion>
      <Accordion class="demo" button:class="secondary">
        {#snippet button()}Button Accordion{/snippet}
        Flamingos are known for their bright pink feathers and distinctive long necks. These birds are social creatures that live in large groups, and a group of flamingos is called a flamboyance. They can often be seen standing on one leg, which helps them conserve body heat.
      </Accordion>
      <Accordion class="demo" button:class="outline">
        {#snippet button()}Button Accordion 2{/snippet}
        Flamingos are known for their bright pink feathers and distinctive long necks. These birds are social creatures that live in large groups, and a group of flamingos is called a flamboyance. They can often be seen standing on one leg, which helps them conserve body heat.
      </Accordion>
    </article>

  </section>

</main>

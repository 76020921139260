import { personStore as person, authLoading } from '@stores';

//TODO: Take key from window.config
const siteKey = '6Le_CikqAAAAAN0mZ33jMr1aAVuTZO4UaZ7ApoJi';

// Helper function to get a cookie by name
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export async function apiRequest(endpoint, options = {}) {
  console.log('API request: ', endpoint);

  const session_key = localStorage.getItem('session_key');
  const session_id = localStorage.getItem('session_id');

  if (!options.headers) {
    options.headers = {};
  }

  // Set CSRF Token Header for POST, PUT, PATCH, DELETE requests
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(options.method?.toUpperCase())) {
    const csrfToken = getCookie('csrftoken');
    if (csrfToken) {
      options.headers['X-CSRFToken'] = csrfToken;
    }
  }

  // Required setting to make the browser include session cookies automatically
  options.credentials = 'include';

  let response;

  try {
    response = await fetch(endpoint, options);
  } catch (error) {
    console.log('API request: ', endpoint, options);
    console.log('API response: ', response);
    throw error;
  }


  // Detect the content type
  const contentType = response.headers.get('content-type') || 'unknown';

  if (!contentType.includes('application/json')) {
    throw new Error(`Unexpected content type, expected 'application/json' but got '${contentType}'`);
  }

  const data = await response.json();
  console.log('API result: ', data);

  if (!response.ok) {
    throw new Error(data.detail || 'API request failed');
  }

  return data;
}

export async function searchDegrees(searchString, country='') {
  try {
    console.log('Searching degrees..', searchString);
    const encodedSearchString = '?name=' + encodeURIComponent(searchString);
    const encodeCountryString = country ? '&country=' + encodeURIComponent(country.toUpperCase()) : '';
    const data = await apiRequest(`${window.config.apiBaseUrl}/degrees/${encodedSearchString}${encodeCountryString}`);
    return data;
  } catch (error) {
    console.error('Failed to search degree', error);
    return { 'error': error }
  }
  return {};
}

export async function searchInstitutions(searchString, country='') {
  try {
    console.log('Searching institutions..', searchString);
    const encodedSearchString = '?name=' + encodeURIComponent(searchString);
    const encodeCountryString = country ? '&country=' + encodeURIComponent(country.toUpperCase()) : '';
    const data = await apiRequest(`${window.config.apiBaseUrl}/institutions/${encodedSearchString}${encodeCountryString}`);
    return data;
  } catch (error) {
    console.error('Failed to search degree', error);
    return { 'error': error }
  }
  return {};
}

async function clearAuth() {
  person.set(null);
  localStorage.removeItem('person_data'); // Store person data in localStorage
}

export async function signOut() {
  const url = `${window.config.apiBaseUrl}/signout`;
  const response = await fetch(url, { credentials: 'include' });
  //const data = await response.json();

  if (!response.ok) {
    console.error('signOut() failed', response);
    //throw new Error(`Error: ${response.statusText}`);
  }
  await clearAuth();
  return response.json();
}

export async function signIn(token) {
  if (!token) {
    console.error('No token provided');
    return false;
  }

  try {
    authLoading.set('api_loading'); // Set loading to api_loading at the start
    const url = `${window.config.apiBaseUrl}/token-login2/?token=${token}`;
    console.log('Attempting login...', url);
    const response = await fetch(url, { credentials: 'include' });
    const data = await response.json();

    if (response.ok) {
      localStorage.setItem('session_key', data.session_key);
      localStorage.setItem('session_id', data.session_id);
      console.log('Login successful:', data.detail);
      return true;
    } else {
      await clearAuth();
      console.error('Login failed:', data.detail);
      return false;
    }
  } catch (error) {
    await clearAuth();
    console.error('Error during login:', error);
    return false;
  }
}


export async function checkAuthStatus() {
  try {
    const cachedAuthStatus = localStorage.getItem('authStatus');
    const cachedAuthTime = localStorage.getItem('authStatusTime');

    // Check if we have a cached status and it's within the 5-minute threshold
    if (cachedAuthStatus && cachedAuthTime) {
      const now = new Date().getTime();
      const lastChecked = parseInt(cachedAuthTime, 10);

      if (now - lastChecked < 5 * 60 * 1000) { // 5 minutes in milliseconds
        console.log('Using cached authentication status...');
        return cachedAuthStatus === 'true';
      }
    }

    if (!person) {
      console.log('Not logged in');
      return false;
    }

    console.log('Pinging server to check authentication status...');
    const response = await apiRequest(`${window.config.apiBaseUrl}/ping/`);

    if (response.detail === "Authenticated") {
      console.log('User is authenticated');
      localStorage.setItem('authStatus', 'true');
      localStorage.setItem('authStatusTime', new Date().getTime().toString());
      return true;
    } else {
      console.log('User is not authenticated');
      await clearAuth();  // Clear auth info if user is not authenticated
      localStorage.setItem('authStatus', 'false');
      localStorage.setItem('authStatusTime', new Date().getTime().toString());
      return false;
    }
  } catch (error) {
    console.error('Failed to check authentication status:', error);
    await clearAuth();  // Clear auth info on error
    localStorage.setItem('authStatus', 'false');
    localStorage.setItem('authStatusTime', new Date().getTime().toString());
    return false;
  }
}
export async function fetchUserInfo() {
  try {
    console.log('Fetching user information...');
    const data = await apiRequest(`${window.config.apiBaseUrl}/person/`);
    person.set(data); // Update the user store with the fetched data
    localStorage.setItem('person_data', JSON.stringify(data)); // Store person data in localStorage
    //statusMessage = `Welcome, ${data.full_name}!`;
    console.log('User information fetched:', data);
  } catch (error) {
    //statusMessage = 'Failed to fetch user information: ' + error.message;
    console.error('Failed to fetch user information:', error);
    person.set(null); // Ensure the store is set to null on failure
  } finally {
    authLoading.set('api_loaded'); // Set authLoading to api_loaded once finished
  }
}

export async function anabinAiSearch() {
  try {
    console.log('Anabin AI Search...');
    const data = await apiRequest(`${window.config.apiBaseUrl}/anabin-ai-search/`, {
      method: 'POST',
    });
    return [ true, data ];
  } catch (error) {
    console.error('Failed to search:', error);
    return [ false, error ];
  }
}

export async function uploadFile(file, onProgress) {
  const url = `${window.config.apiBaseUrl}/upload/`;

  const formData = new FormData();
  formData.append("file", file);

  const xhr = new XMLHttpRequest();
  const total = file.size;

  return new Promise((resolve, reject) => {
    xhr.upload.addEventListener('progress', (event) => {
      //console.log('progress', event);
      if (event.lengthComputable) {
        onProgress(event.loaded, total);
      }
    });

    xhr.open('POST', url, true);

    // Set CSRF Token Header
    const csrfToken = getCookie('csrftoken'); // Assuming you have a getCookie function
    if (csrfToken) {
      xhr.setRequestHeader('X-CSRFToken', csrfToken);
    }

    // Include credentials (cookies, authorization headers, etc.)
    xhr.withCredentials = true;

    xhr.onreadystatechange = function() {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject(new Error(xhr.statusText || 'File upload failed'));
        }
      }
    };

    xhr.send(formData);
  });
}

export async function submitContactForm({ name, email, message }) {
  try {
    // Generate the reCAPTCHA token
    const recaptchaToken = await grecaptcha.execute(siteKey, { action: 'contact_form/submit' });

    // Prepare the payload
    const payload = { name, email, message, recaptcha: recaptchaToken };

    // Make the API request
    const data = await apiRequest(`${window.config.apiBaseUrl}/contact/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    return data;
  } catch (error) {
    console.error('Failed to send message', error);
    return { 'error': error }
  }
}

export async function submitSigninForm({ email }) {
  try {
    // Generate the reCAPTCHA token
    const recaptchaToken = await grecaptcha.execute(siteKey, { action: 'signin_form/submit' });

    // Prepare the payload
    const payload = { email, recaptcha: recaptchaToken };

    // Make the API request
    const data = await apiRequest(`${window.config.apiBaseUrl}/signin-request/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    return data;
  } catch (error) {
    console.error('Failed to sign in', error);
    return { 'error': error }
  }
}

export async function submitTermsForm(agree) {
  try {
    console.log('submitTermsForm()', agree);
    // Generate the reCAPTCHA token
    const recaptchaToken = await grecaptcha.execute(siteKey, { action: 'terms_form/submit' });

    // Prepare the payload
    const payload = { accept: agree, recaptcha: recaptchaToken };

    // Make the API request
    const data = await apiRequest(`${window.config.apiBaseUrl}/accept-terms/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    return data;
  } catch (error) {
    console.error('Failed to accept terms', error);
    return { 'error': error }
  }
}

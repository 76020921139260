<script context="module">
  import { tick } from 'svelte';
  import { writable, get } from 'svelte/store';

  export function getBaseUrl() {
    if ('baseUrl' in window.config) return window.config.baseUrl;
    return import.meta.env.DEV ? import.meta.env.BASE_URL : '';
  }

  // Store to hold the current component
  export let currentComponent = writable();
  export let currentPath = writable();

  export const base = getBaseUrl();
  export const baseUrl = base;

  export function getCurrentPath() {
    let path = window.location.pathname;
    if (base && path.startsWith(base)) {
      path = path.slice(base.length);
    }
    //console.log('getCurrentPath()', path);
    return path === '' ? '/' : path;
  }

  function isSvelteComponent(value) {
    return typeof value === 'function';
  }

  export function navigate(path) {
    const base = getBaseUrl();
    console.log('navigate()', path);
    if (!path.startsWith(base)) {
      path = base + path;
    }
    if (currentPath !== path.slice(base.length)) {
      window.history.pushState(history?.state, '', path);
      //updateComponent();
      window.dispatchEvent(new Event('popstate'));
    }
  }

  let routes = {};
  let _currentComponent = null;

  function getComponentFilename(component) {
    const symbols = Object.getOwnPropertySymbols(component);
    const filenameSymbol = symbols.find(sym => sym.description === 'filename');
    return component[filenameSymbol] || 'Unknown filename';
  }

  function updateComponent() {
    const path = getCurrentPath();
    let component = routes[path] || routes['*'];
    if (!isSvelteComponent(component)) {
      console.error('Not a Svelte component');
      component = routes['*'];
    }
    if (component != _currentComponent) {
      console.log('updateComponent()', path, getComponentFilename(component));
      currentPath.update(_ => getCurrentPath());
      currentComponent.update(_ => {
        _currentComponent = component
        document.getElementById('app').scrollTo(0, 0);
        return component;
      });
    } else {
      //console.log('component did not change, leaving it alone');
    }
  }

  window.addEventListener('popstate', updateComponent);
</script>

<script>
  import { onMount, onDestroy } from 'svelte';

  let props = $props();
  routes = props['routes'];

  function handleLinkClick(event) {
    let target = event.target;

    // Traverse up the DOM to find the closest anchor tag
    while (target && target.tagName !== 'A') {
      target = target.parentElement;
    }

    // If an anchor tag was found and it meets the criteria
    if (target && target.origin === window.location.origin && !target.hasAttribute('download')) {
      // Get the full href and remove the origin part
      const href = target.getAttribute('href');
      const path = href.replace(window.location.origin, '');
      event.preventDefault();
      //console.log('intercept', target, path);
      navigate(path);
    }
  }

  onMount(() => {
    updateComponent();
    document.body.addEventListener('click', handleLinkClick);
  });

  onDestroy(() => {
    document.body.removeEventListener('click', handleLinkClick);
  });
  /*
  $effect(() => {
    setTimeout(() => {
      window.addEventListener('popstate', updateComponent);
    }, 0);
  });
   */
</script>

{#key $currentComponent}
  {#if $currentComponent}
    {@render $currentComponent(token='something')}
  {:else}
    <h1>Error: Component is not defined or failed to load.</h1>
  {/if}
{/key}

<style>
  /*
  div.transition {
    animation: fadeInPage 0.5s;
  }
  @keyframes fadeInPage {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  */
</style>

<script module>
  import './init.js';
</script>

<script>
  //import 'vite/modulepreload-polyfill'

  import { onMount } from 'svelte';

  import { checkAuthStatus } from '@api/api.js';

  import Router from './components/Router.svelte';
  import Layout from './components/Layout.svelte';

  //Pages

  import Home         from '@pages/Home.svelte';

  import Anabin       from '@pages/Anabin.svelte';
  import About        from '@pages/About.svelte';
  import Contact      from '@pages/Contact.svelte';
  import Demo         from '@pages/Demo.svelte';
  import FAQ          from '@pages/FAQ.svelte';
  import GetStarted   from '@pages/GetStarted.svelte';
  import Imprint      from '@pages/Imprint.svelte';
  import Jobs         from '@pages/Jobs.svelte';
  import Press        from '@pages/Press.svelte';
  import Pricing      from '@pages/Pricing.svelte';
  import Privacy      from '@pages/Privacy.svelte';
  import SignIn       from '@pages/SignIn.svelte';
  import Terms        from '@pages/Terms.svelte';
  import Testimonials from '@pages/Testimonials.svelte';

  import NotFound     from '@pages/NotFound.svelte';

  import '@assets/favicon.svg';

  const isProduction = process.env.NODE_ENV === 'production';

  onMount(() => {
    // Initialize with the light mode by default
    //document.documentElement.classList.add('has-light-background');
    checkAuthStatus();
  });
  const routes = {
    '/': Home,
    '/about':        About,
    '/anabin':       Anabin,
    '/contact':      Contact,
    '/demo':         Demo,
    '/faq':          FAQ,
    '/get-started':  GetStarted,
    '/imprint':      Imprint,
    '/jobs':         Jobs,
    '/press':        Press,
    '/pricing':      Pricing,
    '/privacy':      Privacy,
    '/signin':       SignIn,
    '/terms':        Terms,
    '/testimonials': Testimonials,

    '*': NotFound,
  };

</script>

<!--
<svelte:head>
  <script src="https://www.recaptcha.net/recaptcha/api.js?render=6Le_CikqAAAAAN0mZ33jMr1aAVuTZO4UaZ7ApoJi" nonce="{window.config.nonce}" async defer></script>
</svelte:head>
-->

<Layout>
  <Router {routes} />
</Layout>


export function escapeHTML(unsafe) {
  return unsafe.replace(
    /[\u0000-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u00FF]/g,
    c => '&#' + ('000' + c.charCodeAt(0)).slice(-4) + ';'
  )
}

export function loadRecaptcha(siteKey, nonce) {
    return new Promise((resolve, reject) => {
        if (window.grecaptcha) {
            // reCAPTCHA already loaded
            resolve(window.grecaptcha);
            return;
        }

        const script = document.createElement('script');
        script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${siteKey}`;
        script.async = true;
        script.defer = true;
        if (nonce) {
            script.setAttribute('nonce', nonce);
        }
        script.onload = () => {
            resolve(window.grecaptcha);
        };
        script.onerror = () => {
            reject(new Error('Failed to load reCAPTCHA'));
        };

        document.body.appendChild(script);
    });
}

<script>
  import Dialog from './Dialog.svelte';
  import Button from './RippleButton.svelte';
  import Icon from '@iconify/svelte';
  import loginIcon from '@iconify-icons/mdi/login-variant';

  let dialog;
  const onclick = ((event) => {
    console.log('onclick() from LoginForm', dialog);
    if (dialog) {
      dialog.show(event);
    }
  });
</script>

<style>
  Button {
    --pico-nav-link-spacing-horizontal: 1rem;
  }
</style>


<slot {onclick}>
  <Button {onclick}>Sign in</Button>
</slot>

<Dialog bind:this={dialog} icon={loginIcon}>
  <h2 slot="header">Sign in</h2>
  <!-- svelte-ignore a11y-autofocus -->
  <label>Email address: <input type="text" placeholder="your@email.com" autofocus /></label>
  <label>Password: <input type="password" /></label>
  <footer>
    <Button class="outline" type="cancel" onclick={() => dialog.close()}>Cancel</Button>
    <Button type="submit"><Icon icon={loginIcon} width="1rem" />Sign in</Button>
  </footer>
</Dialog>
